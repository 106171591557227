const prod = process.env.NODE_ENV === 'production';

export const GENERAL = {
  SITE_KEY_RECAPTCHA: prod
    ? process.env.NEXT_PUBLIC_SITE_KEY_RECAPTCHA_PROD
    : process.env.NEXT_PUBLIC_SITE_KEY_RECAPTCHA_DEV,
  RECAPTCHA_SECRET_KEY: prod
    ? process.env.NEXT_PUBLIC_RECAPTCHA_SECRET_KEY_PROD
    : process.env.NEXT_PUBLIC_RECAPTCHA_SECRET_KEY_DEV,
  //SITE_KEY_RECAPTCHA: prod ? '6Ld0EdQfAAAAAEaUit_L7ipF3P60LGoxCRbdtHjP' : '6Ld0EdQfAAAAAEaUit_L7ipF3P60LGoxCRbdtHjP',
  //RECAPTCHA_SECRET_KEY:prod ? '6Ld0EdQfAAAAALxn6IEwrlhf70mlr2orgdkvGRFe' : '6Ld0EdQfAAAAALxn6IEwrlhf70mlr2orgdkvGRFe',
  KEY_CRIPTO: prod
    ? process.env.NEXT_PUBLIC_KEY_CRIPTO_PROD
    : process.env.NEXT_PUBLIC_KEY_CRIPTO_DEV,
  URL_FILES: prod
    ? process.env.NEXT_PUBLIC_URL_FILES_PROD
    : process.env.NEXT_PUBLIC_URL_FILES_DEV,
  URL_IMAGES: prod
    ? process.env.NEXT_PUBLIC_URL_IMAGES_PROD
    : process.env.NEXT_PUBLIC_URL_IMAGES_DEV,
};

export const API_LOGIN = {
  BACKEND_URL: prod
    ? process.env.NEXT_PUBLIC_BACKEND_URL_LOGIN_PROD
    : process.env.NEXT_PUBLIC_BACKEND_URL_LOGIN_DEV,
};

export const API_LOG = {
  BACKEND_URL: prod
    ? process.env.NEXT_PUBLIC_BACKEND_URL_LOG_PROD
    : process.env.NEXT_PUBLIC_BACKEND_URL_LOG_DEV,
};

export const API_ADMIN = {
  BACKEND_URL: prod
    ? process.env.NEXT_PUBLIC_BACKEND_URL_ADMIN_PROD
    : process.env.NEXT_PUBLIC_BACKEND_URL_ADMIN_DEV,
  BACKEND_URL_DUMMY: process.env.NEXT_PUBLIC_BACKEND_URL_DUMMY,
};

export const THUMBOR = {
  URL: prod
    ? process.env.NEXT_PUBLIC_THUMBOR_URL_PROD
    : process.env.NEXT_PUBLIC_THUMBOR_URL_DEV,
  SECURITY_KEY: prod
    ? process.env.NEXT_PUBLIC_THUMBOR_SECURITY_KEY_PROD
    : process.env.NEXT_PUBLIC_THUMBOR_SECURITY_KEY_DEV,
};
